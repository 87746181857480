<template>
    <div class="catalog-container">
        <div class="catalog-header">
            <div class="title-container font-libre">
            <span class="h1"> Katalog produktów </span>
            <span class="h2">
                Zapraszamy do zapoznania się z naszymi produktami, zamówienie można złożyć za pośrednictwem e-mail lub w sekcji kontakt. Oferujemy również odbiór i <b> montaż na miejscu </b> "od ręki".
            </span>
        </div>
        </div>
  <div class="parent">
      <Card class="div1 catalog-card">
            <template #header>
                <img src="@/assets/glass.png" style="height: 15rem;padding: 1em" />
            </template>
            <template #title>
                Szkła do lusterek
            </template>
            <template #subtitle>
                Najwyższej jakości szkła do lusterek
            </template>
            <template #content>
                <p>
                    W poniższym katalogu znajduję się spis modeli i marek samochodowych/motocyklowych do których posiadamy szkła: 
                    <br>    
                    <b> Białe </b>
                    <ul>
                        <li> Płaskie - 19,99zł/szt </li>
                        <li> Sferyczne - 29,99zł/szt </li>
                        <li> Asferyczne - 34,99zł/szt </li>
                    </ul>
                    <b> Niebieskie </b>
                    <ul>
                        <li> Sferyczne - 49,99zł/szt </li>
                        <li> Asferyczne - 59,99zł/szt </li>
                    </ul>
                    W przypadku <b> samochodów dostawczych </b> każde szkło jest o <b> 5zł </b> droższe niż wyżej wymienione ceny.
                </p>
            </template>
            <template #footer>
                <Button @click="routerTo('szkla')" icon="pi pi-book" class="p-button button-button" label="Zobacz katalog" />
            </template>
        </Card>
        <Card class="div2 catalog-card">
            <template #header>
                <img src="@/assets/wklad.png" style="height: 15rem;padding: 1em" />
            </template>
            <template #title>
                Wkłady
            </template>
            <template #subtitle>
                Gotowe zestawy plastik montażowy + szkło + podgrzewacz
            </template>
            <template #content>
                <p> W poniższym katalogu znajduję się spis modeli i marek samochodowych do których posiadamy wkłady z dowolnie wybranym szkłem: 
                    <br>
                    <b> Wkłady z szkłem białym: </b>
                    <ul>
                        <li> Płaskim - 39,99zł/szt </li>
                        <li> Sferycznym - 49,99zł/szt </li>
                        <li> Asferycznym - 59,99zł/szt </li>
                    </ul>
                    <b> Wkłady z szkłem niebieskim </b>
                    <ul>
                        <li> Sferyczne - 69,99zł/szt </li>
                        <li> Asferyczne - 79,99zł/szt </li>
                    </ul>
                    W przypadku <b> samochodów dostawczych </b> każde szkło jest o <b> 5zł </b> droższe niż wyżej wymienione ceny.
                </p>
            </template>
            <template #footer>
                <Button @click="routerTo('wklady')" icon="pi pi-book" class="p-button button-button" label="Zobacz katalog" />
            </template>
        </Card>
        <Card class="div3 catalog-card">
            <template #header>
                <img src="@/assets/heater.png" style="height: 15rem;padding: 1em" />
            </template>
            <template #title>
                Ogrzewacze
            </template>
            <template #subtitle>
                Wysokiej jakości samoprzylepna folia grzejąca.
            </template>
            <template #content>
                <p>W poniższym katalogu znajdują się ogrzewacze pasujące do wszystkich rodzaji samochodów oraz motocykli, aby dobrać odpowiednią matę grzewczą należy zmierzyć rozmiar lusterka i wybrać najbardziej pasujący rozmiar ogrzewacza.</p>
                    <b> Rodzaje ogrzewaczy: </b>
                    <ul>
                        <li> GB-02 - 14,99zł/szt </li>
                        <li> GB-06 - 9,99zł/szt </li>
                        <li> GB-07 - 9,99zł/szt </li>
                        <li> GB-08 - 9,99zł/szt </li>
                        <li> GB-10 - 9,99zł/szt </li>
                        <li> GB-11 - 15,99zł/szt </li>
                        <li> GB-16 - 14,99zł/szt </li>
                        <li> GB-21 - 17,99zł/szt </li>
                    </ul>
            </template>
            <template #footer>
                <Button @click="routerTo('/ogrzewacze')" icon="pi pi-book" class="p-button button-button" label="Zobacz katalog" />
            </template>
        </Card>
  </div>
    </div>
</template>

<script>
  import Card from 'primevue/card';
  import Button from 'primevue/button';
  import { useHead } from '@vueuse/head';
  export default {
    components: {
      Card,
      Button
    },
    data() {
        return {
            meta: useHead({
                // Can be static or computed
                title: 'Lusterka Adam - Katalog',
                meta: [
                    {'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8'},
                    {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                    {name: 'description', content: 'P.P.H.U. Import Eksport ADAM jest producentem części do lusterek samochodowych działającycm aktywnie na rynku od poand 20lat. W ofercie znajdują się szkła do lusterek, wkłady oraz ogrzewacze.'},
                    {name: 'keywords', content: 'Lusterka, Szkła do lustrerek, Ogrzewacze, Wkłady, Szkła'}
                ]
                }),
        }
    },
    methods: {
        routerTo(to) {
            this.$router.push(to)
        }
    },
  }
</script>

<style scoped>
.catalog-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgb(241, 240, 240);
}
.title-container {
    display: flex;
    flex-direction: column;
}
.catalog-header {
    padding: 0 2rem;
    margin-top: 4rem;
}
.parent {
    display: grid;
    width: 100%;
    grid-auto-flow: column;
    padding: 3rem 2rem 5rem 2rem;
    min-height: 80vh;
    background-color: rgb(241, 240, 240);

    grid-template-columns: 1fr 1fr 1fr; 
    grid-template-rows: 1fr; 
    gap: 0px 12px; 
    grid-template-areas: 
        ". . . ."; 
}
.p-button {
    background-color: var(--orange10) !important;
    border-color: var(--orange10) !important;
}
.catalog-card {
    min-width: 15rem !important;
}
.catalog-card:deep(.p-card-footer) {
  display: flex;
  justify-content: center;
}
img {
    object-fit: contain;
}
@media (max-width: 1560px) {
    .parent {
        display: grid; 
        grid-template-columns: 1fr 1fr; 
        grid-template-rows: 1fr 1fr; 
        gap: 12px 12px; 
        grid-template-areas: 
            ". ."
            ". .";             
    }
}

@media (max-width: 800px) {
    .parent {
        display: grid; 
        grid-template-columns: 1fr; 
        grid-template-rows: 1fr 1fr 1fr; 
        gap: 12px 0px; 
        grid-template-areas: 
            "."
            "."
            "."
            ".";             
    }
}
.h1 {
    line-height: 1.25; 
    margin: 0 0 10px; 
    font-size: 28px; 
    font-weight: bold;
    color: var(--orange10);
}
.h2 {
    line-height: 1.5; 
    margin: 0 0 10px; 
    font-size: 1em; 
    color: #4b4b4b;
    max-width: max(40rem,60%);
}
b {
    color: var(--orange10)
}


</style>

